import {  PaymentElement, useElements, useStripe,Elements, CardElement} from '@stripe/react-stripe-js';

import { useEffect, useState } from 'react';

const CheckoutForm = () => {
  // const [clientSecret, setClientSecret] = useState<any>("");
  const clientSecret = "sk_test_51OCzlHSFMxo5cmlFu7eWI2JBCuxI2wzD4c7ggiaxiBWOU8Ev36gykco6Juho0IZbBQXV1lgOfTH8lLYzsRPhMzFc00h4ZGstV9"
  const stripe = useStripe();
  const elements = useElements();


  // useEffect(() => {
  //   // Fetch clientSecret from your server
  //   const fetchClientSecret = async () => {
  //     try {
  //       const response = await fetch('YOUR_SERVER_ENDPOINT_TO_GET_CLIENT_SECRET');
  //       const data = await response.json();
  //       setClientSecret(data.clientSecret);
  //     } catch (error) {
  //       console.error('Error fetching clientSecret:', error);
  //     }
  //   };

  //   fetchClientSecret();
  // }, []); // Ensure this effect runs only once on component mount




  const handleSubmit = async (event:any) => {
    event.preventDefault();

    if(!stripe || !elements || !clientSecret){
      console.log('Error: Missing stripe, elements, or clientSecret');
      return;
    }
    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      clientSecret,
      confirmParams: {
        return_url: "/my-learnings",
      },
    });

    if (result.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };

  


  return (
   
    <div className='w-50'>
      <form onSubmit={handleSubmit}>
        <PaymentElement />
        <div className="d-flex justify-content-end">
          <button className='btn btn-primary mt-3 mb-5' type="submit" disabled={!stripe || !elements || !clientSecret}>
            Confirm
          </button>
        </div>
      </form>
    </div>
  );
};

export default CheckoutForm;