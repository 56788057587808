import React from 'react'
import ReactPlayer from 'react-player'

interface IDVideoDetailCard{
    currentVideoUrl:any; 
}

const CourseDetailsCard :React.FC<IDVideoDetailCard>=(props) => {

    const {
        currentVideoUrl={}
    } = props;

  return (
   
    <div className="video-player-container">
      <ReactPlayer
        url=""
        // "https://youtu.be/nLQ-9vfEjUI?si=8dKQUmSFs4w9msCu"
        style={{ border: "0", width: "850px", height: "405px" }}

        // {currentVideoUrl}
        controls={true}
      />
    </div>
  )
}

export default CourseDetailsCard