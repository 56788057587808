import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./about.scss";

// images
import aboutBannerImg1 from "../../../assets/images/about-banner-img-1.png";
import aboutBannerImg2 from "../../../assets/images/about-banner-img-2.png";
import visionImg from "../../../assets/images/vision-img.png";
import locationImg from "../../../assets/images/location-img.png";
import program1 from "../../../assets/images/program-1-img.png";

// icons
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/icon-arrow-right.svg";

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";

const About = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  return (
    <>
      <Header />
      {/* banner section */}
      <section className="about-banner">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="banner-content">
                <p className="page-name">About Us</p>
                <h1 className="banner-title">
                  Our Story: Pioneering Insurance Education for a Secure and
                  <span className="primary-highlilght"> Informed Tomorrow</span>
                  .
                </h1>
                <p className="banner-description">
                  At Assurance, we are driven by a passion for democratizing
                  insurance education. Established in [Year], our vision is to
                  create a community where individuals thrive through
                  comprehensive and accessible learning experiences. Committed
                  to excellence, our team of dedicated experts brings a wealth
                  of knowledge, ensuring that learners are equipped with the
                  skills needed to navigate the complex landscape of insurance
                  confidently. Join us on this educational journey as we
                  redefine the future of insurance knowledge, one learner at a
                  time.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-8 mx-md-auto ">
              <div className="row">
                <div className="col-6">
                  <div className="right-img-wrapper image-one">
                    <img
                      src={aboutBannerImg1}
                      alt="banner image 1"
                      className="banner-img"
                    />
                  </div>
                </div>
                <div className="col-6">
                  <div className="right-img-wrapper image-two">
                    <img
                      src={aboutBannerImg2}
                      alt="banner image 2"
                      className="banner-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner section */}

      {/* vision section */}
      <section className="vision-section">
        <div className="container">
          <h2 className="vision-title">
            Our Vision for a Future Where Insurance Knowledge Empowers Every
            Individual.
          </h2>
          <div className="row">
            <div className="col-lg-7">
              <div className="img-wrapper">
                <img
                  src={visionImg}
                  className="vision-img"
                  alt="vision image"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="description-wrapper">
                <p className="description ml-40 mt-3 mt-md-4 lg-mt-0">
                  At Assurance, our vision is to illuminate the path to
                  financial security by becoming the foremost destination for
                  progressive insurance education. We envision a world where
                  individuals are not just consumers of insurance but informed
                  decision-makers, confidently safeguarding their futures.
                  Through innovation, inclusivity, and a commitment to
                  excellence, we aspire to redefine the standard of insurance
                  education, empowering every learner to navigate the
                  complexities of risk with clarity and confidence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* vision section */}

      {/* location section */}
      <section className="location-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="location-title">Where We Work</h2>
              <div className="location-img-wrapper">
                <div className="overlay"></div>
                <img
                  src={locationImg}
                  alt="location-image"
                  className="location-img"
                />
                <div className="title-sec">
                  <h4 className="title">UAE</h4>
                  <p className="desc">Headquarters</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* location section */}

      {/* join team section */}
      <section className="join-team-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-card">
                <div className="content-sec">
                  <h2 className="content-title">Want to join our Team?</h2>
                  <p className="description">
                    Explore exciting career opportunities with Assurance and be
                    a vital part of our mission to revolutionize insurance
                    education, empowering individuals worldwide for financial
                    security and success.
                  </p>
                </div>
                <div>
                  <a href="" className="btn btn-primary">
                    Check Open Positions
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* join team section */}

      <Footer />
    </>
  );
};

export default About;
