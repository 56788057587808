import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./home.scss";

// images
import bannerImg from "../../../assets/images/banner-img-man.png";
import feature1 from "../../../assets/images/feature-1-img.svg";
import feature2 from "../../../assets/images/feature-2-img.svg";
import feature3 from "../../../assets/images/feature-3-img.svg";
import program1 from "../../../assets/images/program-1-img.png";

// icons
import { ReactComponent as ArrowRightIcon } from "../../../assets/icons/icon-arrow-right.svg";

import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";

const Home = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch<any>();

  return (
    <>
      <Header />

      {/* banner section */}
      <section className="home-banner">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-7">
              <div className="banner-content">
                <h1 className="banner-title">
                  A Comprehensive Guide to Insurance Education for a{" "}
                  <span className="primary-highlilght">Secure Future</span>
                </h1>
                <p className="banner-description">
                  Empower yourself with essential insurance knowledge for
                  financial security and peace of mind through our expert-guided
                  platform.
                </p>
                <div className="btn-wrapper">
                  <a href="" className="btn btn-outline-primary">
                    Learn More
                  </a>
                  <a href="" className="btn btn-primary">
                    Register Now
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-5 d-none d-lg-block">
              <div className="right-img-wrapper">
                <img
                  src={bannerImg}
                  alt="banner image"
                  className="banner-img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner section */}

      {/* features section */}
      <section className="features-section">
        <div className="container">
          <h2 className="features-title">
            Dive into a Wealth of Features Tailored to Enhance Your Insurance
            Learning Journey
          </h2>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="features-card">
                <div className="img-wrapper">
                  <img src={feature1} alt="feature 1 image" />
                </div>
                <h4 className="feature-title">Interactive Learning Modules</h4>
                <p className="feature-description">
                  Embark on an engaging educational journey through our
                  interactive modules designed to make complex insurance
                  concepts.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="features-card mx-lg-auto">
                <div className="img-wrapper">
                  <img src={feature2} alt="feature 2 image" />
                </div>
                <h4 className="feature-title">Interactive Learning Modules</h4>
                <p className="feature-description">
                  Embark on an engaging educational journey through our
                  interactive modules designed to make complex insurance
                  concepts.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mx-auto">
              <div className="features-card ms-lg-auto">
                <div className="img-wrapper">
                  <img src={feature3} alt="feature 3 image" />
                </div>
                <h4 className="feature-title">Interactive Learning Modules</h4>
                <p className="feature-description">
                  Embark on an engaging educational journey through our
                  interactive modules designed to make complex insurance
                  concepts.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* features section */}

      {/* programes section */}
      <section className="programs-section">
        <div className="container">
          <h2 className="programs-title">
            Elevate Your Expertise with Our Top-Tier Insurance Programs.
          </h2>
          <p className="programs-description">
            Explore a curated selection of cutting-edge courses designed to
            propel your understanding of insurance to new heights.
          </p>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div className="program-card">
                <div className="img-wrapper">
                  <img src={program1} alt="program 1 image" />
                </div>
                <div className="program-card-body">
                  <h4 className="program-title">Licentiate</h4>
                  <p className="program-description">
                    Embark on a comprehensive learning journey with our
                    Insurance Licentiate Program
                  </p>
                  <a
                    href="#"
                    className="d-flex align-items-center cursor-pointer w-fit"
                  >
                    <span className="text-primary">Enrol Now</span>
                    <span className="arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <div className="program-card">
                <div className="img-wrapper">
                  <img src={program1} alt="program 1 image" />
                </div>
                <div className="program-card-body">
                  <h4 className="program-title">Licentiate</h4>
                  <p className="program-description">
                    Embark on a comprehensive learning journey with our
                    Insurance Licentiate Program
                  </p>
                  <a
                    href="#"
                    className="d-flex align-items-center cursor-pointer w-fit"
                  >
                    <span className="text-primary">Enrol Now</span>
                    <span className="arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-4 mx-auto">
              <div className="program-card">
                <div className="img-wrapper">
                  <img src={program1} alt="program 1 image" />
                </div>
                <div className="program-card-body">
                  <h4 className="program-title">Licentiate</h4>
                  <p className="program-description">
                    Embark on a comprehensive learning journey with our
                    Insurance Licentiate Program
                  </p>
                  <a
                    href="#"
                    className="d-flex align-items-center cursor-pointer w-fit"
                  >
                    <span className="text-primary">Enrol Now</span>
                    <span className="arrow-icon">
                      <ArrowRightIcon />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center mt-3">
            <a href="" className="btn btn-outline-primary">
              View All Courses
            </a>
          </div>
        </div>
      </section>
      {/* programes section */}

      {/* faq section */}
      <section className="faq-section">
        <div className="container">
          <h2 className="faqs-title">
            Elevate Your Understanding with our Elegant FAQ Section
          </h2>
          <div className="row">
            <div className="col-12">
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingOne">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Accordion Item #1
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingOne"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="text">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> class. This is the first
                        item's accordion body.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingTwo">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseTwo"
                      aria-expanded="false"
                      aria-controls="flush-collapseTwo"
                    >
                      Accordion Item #2
                    </button>
                  </h2>
                  <div
                    id="flush-collapseTwo"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingTwo"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="text">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> class. This is the first
                        item's accordion body.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Accordion Item #3
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <p className="text">
                        Placeholder content for this accordion, which is
                        intended to demonstrate the{" "}
                        <code>.accordion-flush</code> class. This is the third
                        item's accordion body. Nothing more exciting happening
                        here in terms of content, but just filling up the space
                        to make it look, at least at first glance, a bit more
                        representative of how this would look in a real-world
                        application.
                      </p>
                    </div>
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </section>
      {/* faq section */}
      <Footer />
    </>
  );
};

export default Home;
