import React, { useEffect, useState } from "react";
import "./chat.scss";
import { ReactComponent as SendIcon } from "../../assets/icons/icon-send.svg";
import { ReactComponent as MessageQuestionIcon } from "../../assets/icons/icon-message-question.svg";
import { ReactComponent as DownArrowIcon } from "../../assets/icons/icon-arrow-down.svg";
import { chatStore, getChatList } from "../../redux/pages/courseSlice";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../redux/hooks";

interface IDChat {
  subject_id: any;
}

const Chat: React.FC<IDChat> = (props) => {
  const { subject_id = {} } = props;

  const dispatch = useDispatch<any>();
  const [chatData, setChatData] = useState<Array<any>>([]);
  const [InputMessage, setInputMessage] = useState<string>("");

  const chatInfo = useAppSelector((state) => state.course.chatList);

  const handleChatChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputMessage(e.target.value);
  };

  const messageSendHandler = () => {
    const message = InputMessage;
    if (subject_id && message) {
      dispatch(chatStore({ subject_id, message }))
      setInputMessage("");
    }
  };

  useEffect(() => {
    if (chatInfo) {
      setChatData(chatInfo?.chat);
    }
  }, [chatInfo]);

  return (
    <>
      {chatData?.map((chat: any, index: number) => (
        <div className="chat-bubble-wrapper" key={index}>
          {chat?.message_by === "admin" ? (
            <div>
              <div className="avatar-wrap me-3">
                <img src={chat?.profile_image} alt="avatar" />
              </div>
            </div>
          ) : null}
          <div
            className={`chat-bubble ${
              chat?.message_by !== "admin"
                ? "user-chat-bubble"
                : "tutor-chat-bubble"
            }`}
          >
            <p className="message">{chat?.message}</p>
          </div>
        </div>
      ))}
      <div className="input-wrapper">
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            placeholder="Type a message"
            value={InputMessage}
            onChange={handleChatChange}
          />
          <button className="send-btn" onClick={() => messageSendHandler()}>
            <SendIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default Chat;
