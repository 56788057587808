import React, { useCallback, useEffect, useState } from "react";
import "./course-listing.scss";
import Banner from "../../../components/Banner/Banner";
import CourseCard from "../../../components/Cards/CourseCard/CourseCard";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import {
  addtoWishListStore,
  deleteWishListStore,
  getCourseList,
  getWishListItems,
  setCourseList,
} from "../../../redux/pages/courseSlice";

// icons
import { ReactComponent as SortIcon } from "../../../assets/icons/icon-sort.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/icon-arrow-right.svg";
import noRecordsImage from "../../../assets/images/no-data-6.svg";
import ContentLoader from "../../../common/loader/loader";
import Search from "../../../common/Search/Search";

const CourseListing = () => {
  const [courseListData, setCourseListData] = useState<any>([]);
  const [wishListID, setWishListID] = useState<any>([]);

  const [keyWord, setKeyword] = useState<string>("");
  const [courseItemsList, setCourseItemsList] = useState<any>([]);

  const dispatch = useDispatch<any>();
  const courseInfo = useAppSelector((state) => state.course.courseList);
  const wishListItems = useAppSelector((state) => state.course.wishListItems);
  const wishlistInfo = useAppSelector((state) => state.course.wishListltems);
  const isLoading = useAppSelector((state) => state.course.isLoading);

  useEffect(() => {
    dispatch(getCourseList());
    dispatch(getWishListItems());
    return () => {
      dispatch(setCourseList([]));
    };
  }, []);

  useEffect(() => {
    if (wishlistInfo?.data) {
      const mappedData = wishlistInfo.data.map((item: any) => {
        return item.id;
      });
      setWishListID(mappedData);
    }
  }, [wishlistInfo]);

  useEffect(() => {
    if (courseInfo && courseInfo?.length) {
      setCourseListData([...courseInfo]);
      dispatch(setCourseList([]));
    }
  }, [courseInfo, wishListItems]);

  const handleWishlistAction = (subject_id: any) => {
    if (wishListID.includes(subject_id)) {
      setWishListID(wishListID.filter((id: any) => id !== subject_id));
      dispatch(deleteWishListStore({ subject_id })).then(() => {
        dispatch(getWishListItems());
      });
    } else {
      setWishListID([...wishListID, subject_id]);
      dispatch(addtoWishListStore({ subject_id })).then(() => {
        dispatch(getWishListItems());
      });
    }
  };

   const onSearch = useCallback((keyword: string) => {
    if (keyword) {
     const filteredList = courseListData?.filter((course: any) => course?.name?.toLowerCase().includes(keyword.toLowerCase()));
     setCourseItemsList([...filteredList]);
    }
  }, [courseListData]);

  useEffect(() => {
    if (keyWord?.length > 3 && courseListData?.length) {
     const filteredList = courseListData?.filter((course: any) => course?.name?.toLowerCase().includes(keyWord.toLowerCase()));
     setCourseItemsList([...filteredList]);
    } else if(keyWord === "" && courseListData?.length) {
     setCourseItemsList([...courseListData]);
    }
  }, [keyWord, courseListData]);

  return (
    <div className="course-listing-page">
      <Banner />
      <div className="course-listing py-3">
      {!isLoading ? 
        <div className="container-lg">
          <div className="title-section">
            <h4 className="section-title mb-2">Courses</h4>
            <div className="search-filter-wrap mb-4">
              <div className="search-wrap">
               <Search keyword={keyWord} setKeyword={setKeyword} onSearch={onSearch}/>
              </div>
              <div>
                {/* <div className="dropdown sort-dropdown">
                  <button
                    className="btn btn-outline-black dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="me-2">
                      <SortIcon />
                    </span>
                    Sort
                  </button>
                  <ul
                    className="dropdown-menu dropdown-menu-end"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className={`dropdown-item ${sortField === "name" ? "active" : ""
                        }`}
                        href="#"
                        onClick={() => handleSortChange("name")}>
                        Name
                      </a>
                    </li>
                    <li>
                      <a className={`dropdown-item ${sortField === "date" ? "active" : ""
                        }`}
                        href="#"
                        onClick={() => handleSortChange("date")}>
                        Date
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Something else here
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row gx-4 gy-4 mb-5">
              {courseItemsList?.length ? (courseItemsList?.map((course: any, key: any) => (
                <div key={key} className="col-12 col-sm-6 col-lg-4 col-xl-3">
                  <CourseCard
                    courseData={course}
                    wishListID={wishListID}
                    onWishlistAction={handleWishlistAction}
                    source="courseDetails"
                  />
                </div>
              ))) : (
                <div className="no-records-found">
                  <img src={noRecordsImage} alt="No records found" className="no-records-image" />
                  <p>No records found</p>
                </div>)}
          </div>
          {/* <div className="pagination-wrapper">
            <nav aria-label="...">
              <ul className="pagination mb-0">
                <li className="page-item disabled me-4">
                  <a className="page-link" onClick={() => handlePageChange(currentPage - 1)}>
                    <span className="me-2">
                      <PaginationLeftArrowIcon />
                    </span>
                    Prev
                  </a>
                </li>
                {Array(Math.ceil(courseListData.length / itemsPerPage)).fill(null).map((_, index) => (
                  <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                    <a className="page-link" href="#">
                      {index + 1}
                    </a>
                  </li>
                ))}
                <li className={`page-item ${currentPage === Math.ceil(courseListData.length / itemsPerPage) ? 'disabled' : ''}`}>
                  <a className="page-link" onClick={() => handlePageChange(currentPage + 1)}>
                    Next
                    <span className="ms-2">
                      <PaginationRightArrowIcon />
                    </span>
                  </a>
                </li>

              </ul>
            </nav>
          </div> */}
        </div> : <ContentLoader /> }
      </div>
    </div>
  );
};

export default CourseListing;
