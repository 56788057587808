import React, { useCallback, useEffect, useState } from "react";
import "./my-learnings.scss";

// icons
import { ReactComponent as SearchIcon } from "../../../assets/icons/icon-search.svg";
import { ReactComponent as PaginationLeftArrowIcon } from "../../../assets/icons/icon-arrow-left.svg";
import { ReactComponent as PaginationRightArrowIcon } from "../../../assets/icons/icon-arrow-right.svg";
import searchIllustration from "../../../assets/images/search-illustraion.svg";
import noRecordsImage from "../../../assets/images/no-data-6.svg";


// components
import CourseCard from "../../../components/Cards/CourseCard/CourseCard";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../redux/hooks";
import { addtoWishListStore, deleteWishListStore, getWishListItems, mylearningList, setmylearningList, setWishListItems } from "../../../redux/pages/courseSlice";
import ContentLoader from "../../../common/loader/loader";
import Search from "../../../common/Search/Search";

const MyLearnings = () => {
  const [inputValue, setInputValue] = useState("");
  const [mylearning, setmylearning] = useState<any>([]);
  const [wishListdata, setWishListdata] = useState<any>([]);
  const [wishListID, setWishListID] = useState<any>([]);
  const [activeTab, setActiveTab] = useState<any>(1);
  const [keyWord, setKeyword] = useState<string>("");
  const [courseItemsList, setCourseItemsList] = useState<any>([]);
  const [wishListItemsList, setWishListItemsList] = useState<any>([]);



  const dispatch = useDispatch<any>();
  const mylearningInfo = useAppSelector((state) => state.course.mylearningList);
  const wishlistInfo = useAppSelector((state) => state.course.wishListltems);
  const isLoading = useAppSelector((state) => state.course.isLoading);

  useEffect(() => {
    dispatch(mylearningList());
    dispatch(getWishListItems());
    return () => {
      dispatch(setmylearningList([]));
      dispatch(setWishListItems(""));
    };
  }, []);

  useEffect(() => {
    if (mylearningInfo) {
      setmylearning(mylearningInfo?.data);
    }
  }, [mylearningInfo]);

  useEffect(() => {
    if (wishlistInfo?.data) {
      const mappedData = wishlistInfo.data.map((item: any) => {
        return item.id;
      })
      setWishListID(mappedData);
      setWishListdata(wishlistInfo?.data);
    }
  }, [wishlistInfo]);

  const handleWishlistAction = (subject_id: any) => {
    if (wishListID.includes(subject_id)) {
      setWishListID(wishListID.filter((id: any) => id !== subject_id));
      dispatch(deleteWishListStore({ subject_id })).then(() => {
        dispatch(getWishListItems());
      });
    } else {
      setWishListID([...wishListID, subject_id]);
      dispatch(addtoWishListStore({ subject_id })).then(() => {
        dispatch(getWishListItems());
      });
    }
  };

  const onSearch = useCallback((keyword: string) => {
    if(activeTab === 1) {
      if (keyword) {
        const filteredList = mylearning?.filter((course: any) => course?.name?.toLowerCase().includes(keyword.toLowerCase()));
        setCourseItemsList([...filteredList]);
      }
    } else {
      if (keyword) {
        const filteredList = wishListdata?.filter((course: any) => course?.name?.toLowerCase().includes(keyword.toLowerCase()));
        setWishListItemsList([...filteredList]);
      }
    }
  }, [activeTab, mylearning, wishListdata]);

  useEffect(() => {
    if(activeTab === 1) {
      if (keyWord?.length > 3 && mylearning?.length) {
        const filteredList = mylearning?.filter((course: any) => course?.name?.toLowerCase().includes(keyWord.toLowerCase()));
        setCourseItemsList([...filteredList]);
      } else if (keyWord === "" && mylearning?.length) {
        setCourseItemsList([...mylearning]);
      }
    } else {
      if (keyWord?.length > 3 && wishListdata?.length) {
        const filteredList = wishListdata?.filter((course: any) => course?.name?.toLowerCase().includes(keyWord.toLowerCase()));
        setWishListItemsList([...filteredList]);
      } else if (keyWord === "" && wishListdata?.length) {
        setWishListItemsList([...wishListdata]);
      }
    }
  }, [keyWord, mylearning, wishListdata]);

  return (
    <div className="my-learnings">
      <div className="my-learnings-listing">
        {!isLoading ?
          <div className="container-lg">
            <div className="title-section">
              <h4 className="section-title">My Learnings</h4>
              <div className="search-filter-wrap mb-4">
                <div className="search-wrap">
                <Search keyword={keyWord} setKeyword={setKeyword} onSearch={onSearch}/>
                </div>
              </div>
            </div>

            <div className="tab-section">
              <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-learnings-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-learnings"
                    type="button"
                    role="tab"
                    aria-controls="pills-learnings"
                    aria-selected="true"
                    onClick={() => setActiveTab(1)}
                  >
                    Learnings
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-wishlist-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-wishlist"
                    type="button"
                    role="tab"
                    aria-controls="pills-wishlist"
                    aria-selected="false"
                    onClick={() => {
                      setActiveTab(2)
                    }}>
                    Wishlist
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="pills-learnings"
                  role="tabpanel"
                  aria-labelledby="pills-learnings-tab"
                >
                  <div className="row">
                    {courseItemsList?.length ?
                      <div className="col-12">
                        <div className="row gx-4 gy-4 mb-5">
                          {courseItemsList?.map((course: any, key: any) => (
                            <div
                              key={key}
                              className="col-12 col-sm-6 col-lg-4 col-xl-3"
                            >
                              <CourseCard
                                courseData={course}
                                wishListID={wishListID}
                                onWishlistAction={handleWishlistAction}
                                source="mylearning"
                              />
                            </div>
                          ))}
                        </div>
                      </div> : <div className="no-records-found">
                        <img src={noRecordsImage} alt="No records found" className="no-records-image" />
                        <p>No records found</p>
                      </div>}
                    {/* <div className="col-12">
                      <div className="pagination-wrapper">
                        <nav aria-label="...">
                          <ul className="pagination mb-0">
                            <li className="page-item disabled me-4">
                              <a
                                className="page-link"
                                href={mylearning?.links?.[0]?.url}
                              >
                                <span className="me-2">
                                  <PaginationLeftArrowIcon />
                                </span>
                                {mylearning?.links?.[0]?.label}
                              </a>
                            </li>

                            <li className="page-item">
                              <a
                                className="page-link"
                                href={mylearning?.links?.[1]?.url}
                              >
                                {mylearning?.links?.[1]?.label}
                              </a>
                            </li>

                            <li className="page-item ms-4">
                              <a
                                className="page-link "
                                href={mylearning?.links?.[2]?.url}
                              >
                                {mylearning?.links?.[2]?.label}
                                <span className="ms-2">
                                  <PaginationRightArrowIcon />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div> */}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="pills-wishlist"
                  role="tabpanel"
                  aria-labelledby="pills-wishlist-tab"
                >
                  <div className="row">
                    {wishListItemsList?.length ? <div className="col-12">
                      <div className="row gx-4 gy-4 mb-5">
                        {wishListItemsList?.map((course: any, key: any) => (
                          <div className="col-12 col-sm-6 col-lg-4 col-xl-3">
                            <CourseCard
                              courseData={course}
                              wishListID={wishListID}
                              onWishlistAction={handleWishlistAction}
                              source="viewDetails"
                            />
                          </div>
                        ))}
                      </div>
                    </div> : <div className="no-records-found">
                      <img src={noRecordsImage} alt="No records found" className="no-records-image" />
                      <p>No records found</p>
                    </div>}
                    {/* <div className="col-12">
                      <div className="pagination-wrapper">
                        <nav aria-label="...">
                          <ul className="pagination mb-0">
                            <li className="page-item disabled me-4">
                              <a
                                className="page-link"
                                href={wishlistLink?.[0]?.url}        
                              >
                                <span className="me-2">
                                  <PaginationLeftArrowIcon />
                                </span>
                               
                                {wishlistLink?.[0]?.label}
                              </a>
                            </li>
                            <li className="page-item">
                              <a className="page-link"
                                 href={wishlistLink?.[1]?.url}
                               >
                                {wishlistLink?.[1]?.label}
                              </a>
                            </li>

                            <li className="page-item ms-4">
                              <a className="page-link"
                                href={wishlistLink?.[2]?.url}
                              >
                                {wishlistLink?.[2]?.label}
                                <span className="ms-2">
                                  <PaginationRightArrowIcon />
                                </span>
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div> : <ContentLoader />}
      </div>
    </div>
  );
};

export default MyLearnings;
