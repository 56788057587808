import React, { memo } from "react";
import "./Search.scss";
import { ReactComponent as SearchNormalIcon } from "../../../assets/icons/icon-search-normal.svg";
import { ReactComponent as SearchIcon } from "../../assets/icons/icon-search.svg";


interface ISearch {
   placeholder?: string;
   keyword: string;
   setKeyword: (data: any) => void;
   onSearch: (data: any) => void;
}

const Search: React.FC<ISearch> = (props) => {
   const {
      placeholder = "Search",
      keyword = "",
      setKeyword = () => { },
      onSearch = () => { }
   } = props;


   return (
      // <div className="search-wrapper me-3">
      //    <div className="search-box">
      //       <a className="search-icon-button">
      //          <SearchNormalIcon />
      //       </a>
      //       <input
      //          type="text"
      //          placeholder={placeholder}
      //          className="search-form-control"
      //          value={keyword}
      //          onChange={(e) => setKeyword(e.target.value)}
      //          onKeyDown={(e) => {
      //             if (e.code === "Enter") {
      //                onSearch(keyword);
      //             }
      //          }}
      //       />
      //    </div>
      // </div>

      <div className="form-control-wrap">
         <div className="icon-form-control">
            <div className="start-icon">
               <SearchIcon />
            </div>
            <input
               type="text"
               placeholder={placeholder}
               className="form-control"
               value={keyword}
               onChange={(e) => setKeyword(e.target.value)}
               onKeyDown={(e) => {
                  if (e.code === "Enter") {
                     onSearch(keyword);
                  }
               }}
            />
         </div>
      </div>
   );
};

export default memo(Search);
