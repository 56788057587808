import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../../common/interceptors/axiosConfig";
import { logRoles } from "@testing-library/react";

export interface courseState {
  currency: string;
}

const initialState: courseState = {
   currency: "$",
};

const commonUiSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
   setCurrency: (state, { payload }: PayloadAction<any>) => {
      state.currency = payload;
    },

  }
});

export const {
  setCurrency,
} = commonUiSlice.actions;

export default commonUiSlice.reducer;
