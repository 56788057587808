import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../store";
import { axiosInstance } from "../../common/interceptors/axiosConfig";
import { logRoles } from "@testing-library/react";

export interface courseState {
  errors: any;
  isLoading: boolean;
  courseList: any;
  successFlag: boolean;
  courseCategory: any[];
  courseDetails: any[];
  cartList: any[];
  mylearningList: any[];
  wishListltems: any[];
  myLearningDetails: any[];
  vdocipherOtp: any[];
  TestQuestions: any[];
  chatList: any[];
  menuData: any[];
  wishListItems: any[];
  couponsList: any[];
}

const initialState: courseState = {
  errors: null,
  isLoading: false,
  courseList: [],
  successFlag: false,
  courseDetails: [],
  courseCategory: [],
  cartList: [],
  mylearningList: [],
  wishListltems: [],
  myLearningDetails: [],
  vdocipherOtp: [],
  TestQuestions: [],
  chatList: [],
  menuData: [],
  wishListItems: [],
  couponsList: [],
};

const courseSlice = createSlice({
  name: "course",
  initialState,
  reducers: {
    setErrors: (state, { payload }: PayloadAction<any>) => {
      state.errors = payload;
    },
    setLoading: (state, { payload }: PayloadAction<any>) => {
      state.isLoading = payload;
    },
    setCourseList: (state, { payload }: PayloadAction<any>) => {
      state.courseList = payload;
    },
    setCourseDetails: (state, { payload }: PayloadAction<any>) => {
      state.courseDetails = payload;
    },
    setCourseCategory: (state, { payload }: PayloadAction<any>) => {
      state.courseCategory = payload;
    },
    setCartList: (state, { payload }: PayloadAction<any>) => {
      state.cartList = payload;
    },
    setmylearningList: (state, { payload }: PayloadAction<any>) => {
      state.mylearningList = payload;
    },
    setWishListItems: (state, { payload }: PayloadAction<any>) => {
      state.wishListltems = payload;
    },
    setmylearningDetails: (state, { payload }: PayloadAction<any>) => {
      state.myLearningDetails = payload;
    },
    setVdocipherOtp: (state, { payload }: PayloadAction<any>) => {
      state.vdocipherOtp = payload;
    },
    setTestQuestions: (state, { payload }: PayloadAction<any>) => {
      state.TestQuestions = payload;
    },
    setGetChatList: (state, { payload }: PayloadAction<any>) => {
      state.chatList = payload;
    },
    setGetMenuList: (state, { payload }: PayloadAction<any>) => {
      state.menuData = payload;
    },
    setCouponsList: (state, { payload }: PayloadAction<any>) => {
      state.couponsList = payload;
    },
  }
});

export const getCourseList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = '/course/list';
    const response = await axiosInstance({ data: { url: URL, method: 'post', data: null }, token: true });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        if (response.data?.data?.course && response.data?.data?.course?.data) {
          dispatch(setCourseList(response.data?.data?.course?.data));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response))
  }
};

export const getCourseDetails = (id: string, slug: string): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/course/details";
    const requestData = { id, slug };
    const response = await axiosInstance({ data: { url: URL, method: "post", data: requestData }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        if (response.data?.course && response.data?.course) {
          dispatch(setCourseDetails(response.data?.course));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};


export const getcourseCategory = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = '/program/list';
    const response = await axiosInstance({ data: { url: URL, method: 'post', data: null }, token: true });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setCourseCategory(response?.data?.program))
        dispatch(setLoading(false));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response))
  }
};


export const courseCartStore = (body: { program_id: any; subject_id: any }): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/cart/store";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        dispatch(getCartList());
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const getCartList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/cart/index";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        if (response.data && response.data) {
          dispatch(setCartList(response.data));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const removeCart = (body: { cart_id: any; item_id: any }): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/cart/delete/item";
    const response = await axiosInstance({
      data: { url: URL, method: "post", data: body }, token: true,
    });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        dispatch(getCartList());
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const cartCheckout = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/cart/checkout";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const checkOut = (body: { cart_id: any; item_id: any }): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "cart/checkout";
    const response = await axiosInstance({
      data: { url: URL, method: "post", data: body }, token: true,
    });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};


export const mylearningList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/mylearnings";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        if (response.data) {
          dispatch(setmylearningList(response.data?.course));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const myLearningDetails = (id: string, slug: string): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/mylearnings/details";
    const requestData = { id, slug };
    const response = await axiosInstance({ data: { url: URL, method: "post", data: requestData }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        if (response.data?.course && response.data?.course) {
          dispatch(setmylearningDetails(response.data?.course));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};



export const addtoWishListStore = (body: { subject_id: any }): AppThunk => async (dispatch) => {
  // dispatch(setLoading(true));
  try {
    let URL = "/wishlist/store";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        if (response.data) {
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const deleteWishListStore = (body: { subject_id: any }): AppThunk => async (dispatch) => {
  // dispatch(setLoading(true));
  try {
    let URL = "/wishlist/delete";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        if (response.data) {
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const getWishListItems = (): AppThunk => async (dispatch) => {
  // dispatch(setLoading(true));
  try {
    let URL = "/wishlist/index";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        if (response.data?.wishlist && response.data?.wishlist) {
          dispatch(setWishListItems(response.data?.wishlist));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const questionList = (id: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/mylearing/test/index";
    const requestData = { id }
    const response = await axiosInstance({ data: { url: URL, method: "post", data: requestData }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        if (response.data && response.data) {
          dispatch(setTestQuestions(response.data?.test));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};


export const getVdocipherOtp = (vdocipher_id: any): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/vdocipher/generate/otp";
    const requestData = { vdocipher_id };
    const response = await axiosInstance({ data: { url: URL, method: "post", data: requestData }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        if (response.data && response.data) {
          dispatch(setVdocipherOtp(response.data));
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const getCoupons = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/coupon/index";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setCouponsList(response.data.coupons))
        dispatch(setLoading(false));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};


export const applyCoupon = (body: { coupon_code: any }): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/cart/apply/coupon";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};


export const chatStore = (body: { subject_id: any, message: any }): AppThunk => async (dispatch) => {
  // dispatch(setLoading(true));
  try {
    let URL = "/chat/store";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: body }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        dispatch(getChatList(body?.subject_id));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const getChatList = (subject_id: any): AppThunk => async (dispatch) => {
  // dispatch(setLoading(true));
  try {
    let URL = "/chat/list";
    const requestData = { subject_id }
    const response = await axiosInstance({ data: { url: URL, method: "post", data: requestData }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === "success") {
        dispatch(setLoading(false));
        if (response.data) {
          if (response.data && response.data) {
            dispatch(setGetChatList(response.data));
          }
        }
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};

export const menuList = (): AppThunk => async (dispatch) => {
  dispatch(setLoading(true));
  try {
    let URL = "/menu/index";
    const response = await axiosInstance({ data: { url: URL, method: "post", data: null }, token: true, });
    if (response.data) {
      if (response.data.status && response.data.status === 'success') {
        dispatch(setLoading(false));
        dispatch(setGetMenuList(response.data?.menu));
      }
    }
  } catch (error: any) {
    dispatch(setLoading(false));
    dispatch(setErrors(error?.response));
  }
};


export const {
  setLoading,
  setErrors,
  setCourseList,
  setCourseCategory,
  setCourseDetails,
  setCartList,
  setmylearningList,
  setWishListItems,
  setmylearningDetails,
  setVdocipherOtp,
  setTestQuestions,
  setGetChatList,
  setGetMenuList,
  setCouponsList,
} = courseSlice.actions;

export default courseSlice.reducer;
