import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useAppSelector } from "../../redux/hooks";
import { getProfileData } from "../../redux/pages/profileSlice";
import ContentLoader from "../../common/loader/loader";

const PostLoginLayout = () => {

  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  const profileData = useAppSelector((state) => state.profile.getProfileData);

  useEffect(() => {
    dispatch(getProfileData());
  }, []);

  useEffect(() => {
    if (profileData) {
      setIsLoaded(true);
      if (profileData?.phone_verified === false) {
        navigate("/finish-registration", { replace: true });
      } else {
        navigate("/courses", { replace: true });
      }
    }
  }, [profileData]);

  const userTemplate = (
    <>
      < Header />
      <main>
        <Outlet />
      </main>
      <Footer />
    </>
  );

  return (
    <>
      {isLoaded ? userTemplate :  <ContentLoader/>}
    </>
  );
};

export default PostLoginLayout;
