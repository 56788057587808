import React from "react";
import "./cart-item-card.scss";

// icons
import { useDispatch } from "react-redux";
import { removeCart } from "../../../redux/pages/courseSlice";
import { useAppSelector } from "../../../redux/hooks";

interface IDCart {
  courseData: any;
};

const CartItemCard: React.FC<IDCart> = (props) => {
  const {
    courseData = {}
  } = props;

  const dispatch = useDispatch<any>();
  const currency = useAppSelector((state) => state.common.currency);

  const cart_id = courseData.cart_id
  const item_id = courseData.id

  const deleteHandler = () => {
    if (cart_id && item_id) {
      dispatch(removeCart({ cart_id, item_id }));
    }
  }

  return (
    <div className="cart-item-card">
      <div className="img-sec">
        <div className="thumbnail-wrap">
          <img
            src={courseData.thumb_image}
            className="thumbnail"
            alt="course-thumbnail"
          />
        </div>
      </div>

      <div className="course-card-body">
        <div className="details">
          <div className="title-wrap">
            <h3 className="course-card-title">{courseData?.name}</h3>
            <p className="course-card-desc">{courseData?.description}</p>
          </div>
          <div className="d-flex btn-sec">
            <button className="btn btn-sm btn-link" onClick={deleteHandler}>Remove</button>
            {/* <button className="btn btn-sm btn-link-black">
              Add to wishlist
            </button> */}
          </div>
        </div>
        <div>
          <div className="price-wrap">
            <p className="new-price text-end">{currency}{(+courseData?.actual_price)?.toFixed(2)}</p>
            <p className="old-price striked text-end">{currency}{(+courseData?.price)?.toFixed(2)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItemCard;
