import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// components
import CategoryCard from "../../../../../components/Cards/CategoryCard/CategoryCard";
import { useAppSelector } from "../../../../../redux/hooks";
import { registerFinish } from "../../../../../redux/pages/loginSlice";
import { RootState } from "../../../../../redux/rootReducer";
import { showNotification } from "../../../../../common/toast/toast";

interface Category {
  setIndex: (data: any) => void;
}
const CategorySelection = forwardRef<any, Category>((props, ref) => {
  const { setIndex = () => {} } = props;

  const registerFinishRef = useRef<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [isSuccess, setIsSucess] = useState<boolean>(false);
  const [categories, setCategories] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>("");

  const courseCategories = useAppSelector((state) => state.course.courseCategory);
  const profileData = useAppSelector((state) => state.profile.getProfileData);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm();

  useImperativeHandle(ref, () => ({
    onSaveFinishTrigger: () => {
      registerFinishRef?.current?.requestSubmit();
    },
  }));

  useEffect(() => {
    if (profileData && profileData?.phone_verified === true) {
      navigate("/courses", { replace: true });
    }
  }, [profileData]);

  useEffect(() => {
    setCategories(courseCategories);
  }, [courseCategories]);

  useEffect(() => {
    if (isSuccess) {
      setIsSucess(true); 
    }
  }, [isSuccess, navigate]);

  const onSubmit = async () => {
    if (selectedCategory) {
      dispatch(registerFinish({ program: selectedCategory }, setIndex));
    } else {
      showNotification({
        message: "Select a category",
        theme: "light",
        type: "warn",
      });
    }
  };
  
  return (
    <div className="category-section  mb-5">
      <form ref={registerFinishRef} onSubmit={handleSubmit(onSubmit)}>
        <div className="row g-4">
          {categories?.map((category: any, key: any) => (
            <div key={key} className="col-6 col-lg-3">
              <CategoryCard
                category={category}
                selecetdCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setIndex={setIndex}
              />
            </div>
          ))}
        </div>
      </form>
    </div>
  );
});

export default CategorySelection;
